<script>
export default {
  name: 'datepicker',
  props: {
    startDate: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      year: null,
      month: null,
      optionMonth: null,
      form: []
    }
  },
  methods: {
    cal_date() {
      const REPORT_START_DATETIME = new Date()
      const REPORT_END_DATETIME = new Date(this.startDate)

      const startYear = REPORT_START_DATETIME.getFullYear()
      const endYear = REPORT_END_DATETIME.getFullYear()

      const currentMonth = REPORT_START_DATETIME.getMonth() + 1
      const endMonth = REPORT_END_DATETIME.getMonth() + 1
      let month

      for (let year = startYear; year >= endYear; year--) {
        let item = {
          year: year,
          month: []
        }
        if (year == startYear && year != endYear) {
          for (month = 1; month <= currentMonth; month++) {
            item.month.push(month)
          }
        }
        if (year < startYear && year > endYear) {
          for (month = 1; month <= 12; month++) {
            item.month.push(month)
          }
        }
        if (year == endYear && year != startYear) {
          for (month = endMonth; month <= 12; month++) {
            item.month.push(month)
          }
        }
        if (year == endYear && year == startYear) {
          for (month = endMonth; month <= currentMonth; month++) {
            item.month.push(month)
          }
        }
        this.form.push(item)
      }
    },
    selected(event) {
      let year
      if (event) year = event.target.value * 1
      else year = this.year
      const result = this.form.find(data => {
        return data.year === year
      })
      this.optionMonth = result.month
      // this.changeHandler()
    },
    changeHandler() {
      this.$emit('getData', this.year, this.month, 'from picker')
    }
  },
  mounted() {
    this.year = new Date().getFullYear()
    this.month = new Date().getMonth() + 1
    this.cal_date()
    this.selected()
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
